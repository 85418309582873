<template>
    <div
      class=" flex px-2.5 py-12 xlg:w-[640px] flex-col items-center justify-center w-full gap-6 xlg:px-0 sm:px-16 pb-20 xlg:pb-32">
      <h1 class=" text-mainText heading-large">FAQ</h1>
      <div class="w-full overflow-hidden rounded-t-xl rounded-b-xl">
        <el-collapse class="w-full ">
          <el-collapse-item title="How do I find my username?" name="1" class="">
            <div class="acc-content">
              <p class="body-medium text-faqs-color">
                To find your own username, log into the OBB Platform and click on your profile in the bottom left hand
                corner on the home screen. Your username will be displayed next to your profile picture. To find any
                username, simply view a user profile and the username will be displayed next to their profile picture.
              </p>
            </div>
          </el-collapse-item>
  
          <el-collapse-item title="How do I get help with my purchase?" name="2" class="">
            <div class="acc-content">
              <p class="body-medium text-faqs-color">
                To get help with your purchase, visit
                <a class="text-[#A855F7] underline" href="https://ohbabygames.com/support" target="_blank">
                  https://ohbabygames.com/support
                </a>
                where you can join our Discord support channel or submit a request via our support form.
              </p>
            </div>
          </el-collapse-item>
  
          <el-collapse-item title="How do I see my purchases?" name="3" class="">
            <div class="acc-content">
              <p class="body-medium text-faqs-color">
                You can only see purchases for your own account. Open the OBK game and view your inventory to see your
                purchases.
              </p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="How long does it take to receive my purchase?" name="4" class="">
            <div class="acc-content">
              <p class="body-medium text-faqs-color">Once you have confirmed your payment, your purchase will appear in your OBK game inventory instantly.</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="What is your return/refund policy?" name="5" class="">
            <div class="acc-content">
              <p class="body-medium text-faqs-color">All sales are final, no refunds or returns.</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="What happens if the username I am purchasing for already owns the items?" name="6" class="">
            <div class="acc-content">
              <p class="body-medium text-faqs-color">If you buy an item that a username already owns, it won't be purchasable.</p>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
  </template>
  
  <style>

.el-collapse .el-collapse-item__header {
  border-bottom: 1px solid #2d2d2d;
  color: #9C93B3;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  padding: 8px 16px;
  background-color: #221E2A;
}

.el-collapse .is-active {
  color: #EFEDF2;
}

.el-collapse .acc-content {
  padding: 12px 16px;
  border-bottom: 1px solid #2d2d2d;
  font-size: 16px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
}

.el-collapse .el-collapse-item__content {
  background-color: #221E2A;
  color: #9C93B3;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding-bottom: 0;
}

.el-collapse .el-collapse-item__wrap {
  border-top: 1px solid #2d2d2d;
  border-bottom: none;
}

.el-collapse .el-icon {
  content: url('@/assets/icons/add.svg');
  width: 12px;
  height: 12px;
  user-select: none;
}

.el-collapse .el-collapse-item__arrow.is-active {
  transform: rotate(45deg);
}

.el-collapse .el-collapse-item__header {
    text-align: start;
    height: auto;
  }
  
.el-collapse {
    display: flex;
    gap: 4px;
    flex-direction: column;
    border:none !important ;
  }
  
.el-collapse .el-collapse-item {
    background-color: #16131b;
  }
  </style>